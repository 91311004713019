<template>
    <div class="height1">
        <!--      头部      -->
        <div class="iq-top-navbar">
            <el-page-header @back="goBack" :content="AddorEdit">
            </el-page-header>
        </div>
        <div class="center-content">
            <div class="iq-card-body">
                <div class="from-box">
                    <el-form ref="videosForm" :model="videosForm" :rules="rules" label-width="150px">
                        <el-form-item label="合辑名称" prop="name">
                            <el-input type="textarea" v-model="videosForm.name" maxlength="50" show-word-limit>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="视频分类">
                            <el-cascader v-model="videosForm.categoryId" :options="videosCategory"
                                :props="{ checkStrictly: true, emitPath: false, value: 'id', label: 'name', children: 'children' }">
                            </el-cascader>
                        </el-form-item>
                        <el-form-item label="来源">
                            <el-input class="w-18" v-model="videosForm.source"></el-input>
                        </el-form-item>
                        <el-form-item label="权限设置">
                            <el-radio-group v-model="videosForm.limits">
                                <el-radio :label="0">完全公开</el-radio>
                                <el-radio :label="1">内部用户开放</el-radio>
                                <el-radio :label="2">私密发布</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="合辑介绍">
                            <el-input type="textarea" v-model="videosForm.introduce"></el-input>
                        </el-form-item>
                        <el-form-item label="合辑封面" prop="collectionUrl">
                            <el-upload :action="FileUploadUrl" class="avatar-uploader" :show-file-list="false"
                                :on-success="handleAvatarSuccess">
                                <img v-if="videosForm.collectionUrl" :src="videosForm.collectionUrl" class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                <!-- <div class="el-upload__tip" slot="tip">如果封面不上传，就默认视频的界面</div> -->
                            </el-upload>
                        </el-form-item>
                        <el-form-item class="btn-bottom">
                            <el-button @click="cancelBtn('videosForm')">取消</el-button>
                            <el-button type="primary" @click="saveBtn('videosForm')">保存</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Videos } from "../../../components/HospitalDomain/Videos/Videos";
export default {
    name: "AddCollection",
    computed: {
        // headers() {
        //     return {
        //         "Authorization": 'Bearer' + ' ' + JSON.parse(sessionStorage.getItem(this.ClientId + 'Token')).access_token
        //     }
        // }
    },
    data() {
        var videos = new Videos(this.TokenClient, this.Services.Healthy)
        return {
            VideosDomain: videos,
            AddorEdit: this.$route.query.title,
            organizationId: JSON.parse(sessionStorage.getItem('userInfo')).organizationId,
            id: this.$route.query.id, //列表页id
            videosForm: {
                name: '',
                source: '',
                limits: 0,
                introduce: '',
                collectionUrl: '',
                categoryId: 1,
                collectionType: 2,
                organizationId: JSON.parse(sessionStorage.getItem('userInfo')).organizationId,
            },
            videosCategory: [],
            FileUploadUrl: this.Services.Healthy + '/api/Upload',
            rules: {
                name: [
                    { required: true, message: '请输入视频名称', trigger: 'blur' },
                ],
                collectionUrl: [
                    { required: true, message: '请上传合辑封面', trigger: 'blur' },
                ],
            },
            uploader: null
        }
    },
    mounted() {
        this.LoadCategory();
        if (this.id) {
            this.LoadFrom()
        }
    },
    methods: {
        LoadCategory(success, error) {
            var _this = this;
            _this.VideosDomain.Categories(
                function (data) {
                    _this.videosCategory = data.data;
                    _this.videosForm.categoryId = _this.videosCategory[0].id
                },
                function (err) {
                    console.log(err);
                });
        },
        goBack() {
            this.$router.go(-1);
        },
        handleAvatarSuccess(res, file) {
            this.videosForm.collectionUrl = `${this.Services.Healthy}${res[0].completeFilePath}`;
            this.$refs['videosForm'].clearValidate(['collectionUrl'])
        },
        cancelBtn(formName) {
            this.$refs[formName].resetFields();
            this.$router.go(-1);
        },
        LoadFrom() {
            var _this = this;
            _this.VideosDomain.CollectionDetail(_this.id,
                function (data) {
                    _this.videosForm = data.data;

                }, function (err) { console.log(err); });
        },
        saveBtn(formName) {
            var _this = this;
            _this.videosForm.operationUserName = JSON.parse(sessionStorage.getItem('userInfo')).name
            _this.videosForm.organizationName = JSON.parse(sessionStorage.getItem('userInfo')).organizationName
            console.log(this.videosForm)
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (_this.videosForm.id) {
                        _this.VideosDomain.EditCollection(_this.videosForm,
                            function (data) {
                                _this.$refs[formName].resetFields();
                                _this.$router.go(-1);

                            },
                            function (err) {
                                console.log(err);
                            });
                    } else {
                        _this.VideosDomain.AddCollection(_this.videosForm,
                            function (data) {
                                _this.$refs[formName].resetFields();
                                _this.$router.go(-1);
                            },
                            function (err) {
                                console.log(err);
                            });
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });

        },
    }
}
</script>

<style scoped>

.el-page-header {
    line-height: 73px;
    padding-left: 20px;
}

/*content*/


.from-box {
    position: relative;
    margin-top: 20px;
    height: 100%;
    overflow-x: hidden;
    width: 100%;
    /*box-shadow:0px 10px 5px -10px #999;*/
}

.from-box .el-form {
    max-width: 800px;
    width: 80%;
}

.form-item {
    padding-top: 30px;

}

.btn-bottom {
    bottom: 0;
}
</style>
<style>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar {
    width: 178px;
    height: 178px;
    display: block;
}

.video-avatar {
    width: 100%;
    min-height: 60vh;
}
</style>
